<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :span="11">
        <ayl-main
          title="待排班人员"
          isCenter="center"
          :isTop="true"
          padding="10px"
          :isCollapse="false"
          class="aylmain"
        >
          <div slot="main" style="padding: 24px 24px 0px 24px">
            <div style="min-height:40px;">
              <div
                class="el-input el-input--mini el-input--suffix"
                style="padding: 0 21px 0 16px; width: 200px; float: left;"
              >
                <el-input v-model="search" size="mini" placeholder="输入姓名筛选 " />
              </div>
              <!-- <div style="float:left">
                  <el-button
                    style="float: left; margin-top: 14px;"
                    type="success"
                    class="btn-success-hw"
                    @click="onSearch"
                  >查询</el-button>
              </div>-->
            </div>
            <el-table
              :data="finalLeftTable"
              style="width: 100%"
              @selection-change="handleLeftSelectionChange"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column type="index" label="序号" width="120"></el-table-column>
              <el-table-column label="姓名" prop="name"></el-table-column>
              <el-table-column label="人员类型" prop="personTypeDes"></el-table-column>
              <el-table-column label="电话" prop="phone"></el-table-column>
            </el-table>
            <div style="min-height:40px;"></div>
          </div>
        </ayl-main>
      </el-col>
      <el-col :span="2" style="display: table;min-height:260px;">
        <div class="aylmain-btn">
          <div :class="btnClass" @click="onClick2Right">
            <i class="el-icon-right"></i>
          </div>
          <div :class="btnClass_" @click="onClick2Left">
            <i class="el-icon-back"></i>
          </div>
        </div>
      </el-col>
      <el-col :span="11">
        <ayl-main
          title="已排班人员"
          isCenter="center"
          padding="10px"
          :isCollapse="false"
          class="aylmain"
        >
          <div slot="main" style="padding: 24px 24px 0px 24px">
            <div style="min-height:40px;"></div>
            <el-table
              ref="multipleTable"
              :data="rightTable"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleRightSelectionChange"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column type="index" label="序号" width="120"></el-table-column>
              <el-table-column label="姓名" prop="name"></el-table-column>
              <el-table-column label="人员类型" prop="personTypeDes"></el-table-column>
              <el-table-column label="电话" prop="phone"></el-table-column>
            </el-table>
            <div style="min-height:40px;"></div>
          </div>
        </ayl-main>
      </el-col>
    </el-row>
    <div style="margin: 24px;display: flex;justify-content: center">
      <el-button class="btn" :loading="loading" @click="onSubmit">保 存</el-button>
      <el-button type="danger" @click="onClose">关 闭</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      nav: [{ name: "人员排班分配", path: "" }],
      loading: false,
      leftTable: [],
      rightTable: [],
      leftSelection: [],
      rightSelection: [],
      search: null,
      btnClass: "right_icon",
      btnClass_: "right_icon",
      debug:0,
    };
  },
  methods: {
    handleLeftSelectionChange(val) {
      let self = this;
      self.leftSelection = val;
      if (val.length !== 0) {
        self.btnClass = "right_icon right_icon_color";
      } else {
        self.btnClass = "right_icon";
      }
    },
    handleRightSelectionChange(val) {
      let self = this;
      self.rightSelection = val;
      if (val && val.length) {
        self.btnClass_ = "right_icon right_icon_color";
      } else {
        self.btnClass_ = "right_icon";
      }
    },
    // _isExist(id, tableData) {
    //   return tableData.some(function(item) {
    //     return item.personId === id;
    //   });
    // },
    //从originalArr中删除arr
    _deleteArr(arr, originalArr) {
      if (arr.length && originalArr.length) {
        let copy = new Map();
        originalArr.forEach(item => {
          copy.set(item.personId, item);
        });
        arr.forEach(item => {
          copy.delete(item.personId);
        });
        let re = [];
        for (let v of copy.values()) {
          re.push(v);
        }
        return re;
      } else {
        return originalArr;
      }
    },
    onClick2Right() {
      let self = this;      
      if (self.leftSelection.length) {
        if(self.$dayjs(self.dataFromDialog.scheduleDay).isBefore(self.$dayjs(self.$dayjs().format("YYYYMMDD")))) {
          self.$notify({
            title: "抱歉",
            message: `今天之前的排班不可分配`,
            type: "warning"
          });
          return;
        }
        self.rightTable = self.rightTable.concat(self.leftSelection);
        //移除数据
        self.leftTable = self._deleteArr(self.leftSelection, self.leftTable);
      }
    },
    onClick2Left() {
      let self = this;
      if (self.rightSelection.length) {
        if(self.$dayjs(self.dataFromDialog.scheduleDay).isBefore(self.$dayjs(self.$dayjs().format("YYYYMMDD")))) {
          self.$notify({
            title: "抱歉",
            message: `今天之前的排班不可分配`,
            type: "warning"
          });
          return;
        }
        self.leftTable = self.leftTable.concat(self.rightSelection);
        //移除数据
        self.rightTable = self._deleteArr(self.rightSelection, self.rightTable);
      }
    },
    async onSubmit() {
      var self = this;

      if (self.loading) return; //防止重复提交
      if(self.$dayjs(self.dataFromDialog.scheduleDay).isBefore(self.$dayjs(self.$dayjs().format("YYYYMMDD")))) {
        self.$notify({
          title: "抱歉",
          message: `今天之前的排班不可分配`,
          type: "warning"
        });
        return;
      }
      if(!self.rightTable.length) {
        self.$notify({
          title: "出错了",
          message: `已排班人员不能为空`,
          type: "error"
        });
        return;
      }
      self.loading = true;
      try {
        await self.$api_hw.scheduling_saveSchedule({
          sanitationScheduleDistributeVO: {
            shiftId: self.dataFromDialog.shiftId,
            scheduleDay: self.dataFromDialog.scheduleDay,
            bidId: self.dataFromDialog.bidId,
            gridId: self.dataFromDialog.gridId,
            scheduleId: self.dataFromDialog.scheduleId,
            personIdList: self.rightTable.map(item => item.personId).join(",")
          }
        });
        self.$notify({
          title: "成功",
          message: `${self.nav[0].name}成功`,
          type: "success"
        });

        BUS.$emit(BUSEVENT.REFRESH_SCHEDULING_LIST, 0);
        self.onClose();
      } catch (e) {
        console.error(e);
      } finally {
        self.loading = false;
      }
    },
    onClose() {
      this.$emit("onClose");
    }
  },
  computed: {
    finalLeftTable() {
      let self = this;
      return self.leftTable.filter(
        data =>
          !self.search ||
          data.name.toLowerCase().includes(self.search.toLowerCase())
      );
    }
  },
  async mounted() {
    let self = this;
    let _dt = await self.$api_hw.scheduling_personScheduleDetail({
      shiftId: self.dataFromDialog.shiftId,
      scheduleDay: self.dataFromDialog.scheduleDay
    });
    self.leftTable = _dt["待排班"];
    self.rightTable = _dt["已排班"];
  }
};
</script>

<style lang='sass' scoped>
.clearBox2
  overflow-x: visible !important
  .allot-main
    width: 100%
    height: 100%
    &-left
      z-index: 333
      width: 300px
      height: 100%
      display: inline-block
      vertical-align: top
      background: rgba(255,255,255,1)
      overflow: auto
      &-title
        text-align: center
        padding: 24px
        @include clearfix
        .title
          font-size: $fontX
          color: $black
          border-bottom: 1px solid rgba(232,232,232,1)
    &-right
      margin-left: 24px
      display: inline-block
      width: 100%
      height: 100%
      .aylmain
        width: 45%
        display: inline-block
        vertical-align: middle
        margin-top: 20px

.aylmain-btn
  width: 100%
  text-align: center
  height: 80px
  margin: 0px auto
  display: table-cell
  vertical-align: middle
  font-size: 22px
  .right_icon
    width: 24px
    height: 24px
    background: #AAADB3
    color: #eeeeee
    border-radius: 50%
    margin: 15px auto

.btn
  width: 64px
  height: 28px
  background-color: rgba(26,188,156,1)
  border-radius: 4px
  color: #FFFFFF
  border-color: #1ABC9C
/deep/.el-button--success
  color: #FFFFFF
  background-color: #1ABC9C
  border-color: #1ABC9C

.right_icon_color
  background: #1ABC9C !important

</style>
